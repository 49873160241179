import "./App.css";
import React from "react";
import UiDimensions from "./context/UiDimensions";
import Coffee from "./pages/Coffee/Coffee";

const App = () => {
  const Width = UiDimensions().innerWidth;
  const Height = UiDimensions().innerHeight;

  return (
    <React.Fragment>
      <Coffee W={Width} H={Height} />
    </React.Fragment>
  );
};

export default App;
