import React, { Fragment, useState } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import classess from "./Modal.module.css";
import BTN from "../UI/BTN/BTN";
import UiText from "../UI/Text/UiText";
import UiPass from "../UI/Text/UiPass";
import UiUploading from "../UI/Upload/UiUploading";

const Modal = (propse) => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Error1, setError1] = useState("");
  const [Error2, setError2] = useState("");
  const [Image, setImage] = useState(null);
  const [Name, setName] = useState("");
  const [EmailRegister, setEmailRegister] = useState("");
  const [Category, setCategory] = useState("");
  const [PasswordRegister, setPasswordRegister] = useState("");
  const [Error3, setError3] = useState("");
  const [Error4, setError4] = useState("");
  const [Error5, setError5] = useState("");
  const [Error6, setError6] = useState("");
  const OnChange = (e, event) => {
    if (e === "EMAIL") {
      setEmail(event.target.value);
      setError1(false);
    }
    if (e === "PASSWORD") {
      setPassword(event.target.value);
      setError2(false);
    }
    if (e === "NAME") {
      setName(event.target.value);
      setError3(false);
    }
    if (e === "EMAILREGISTER") {
      setEmailRegister(event.target.value);
      setError4(false);
    }
    if (e === "CATEGORY") {
      setCategory(event.target.value);
      setError5(false);
    }
    if (e === "PASSWORDREGISTER") {
      setPasswordRegister(event.target.value);
      setError6(false);
    }
  };
  const onClick = (e) => {
    if (e === "EMAIL") {
      setEmail("");
      setError1(false);
    }
    if (e === "PASSWORD") {
      setPassword("");
      setError2(false);
    }
    // if (e === "BACK") {
    //   propse.setRegister(false);
    //   propse.setLogin(false);
    // }
  };
  return (
    <Fragment>
      <Box
        className={classess.Wrapper}
        sx={{
          animation:
            propse.W <= 560
              ? propse.Move === true
                ? "RTL 0.3s ease-in-out 0s 1 forwards"
                : propse.Move === false
                ? "LTR 0.3s ease-in-out 0s 1 backwards"
                : null
              : null,
        }}
      >
        <Box className={classess.WrapperBox}>
          <Box className={classess.Back}>
            <IconButton
              sx={{ width: "40px", height: "40px", marginLeft: "-14px" }}
              onClick={() => propse.setMove(false)}
            >
              <ArrowBackIosRoundedIcon
                sx={{ cursor: "pointer", color: "#707070" }}
              />
            </IconButton>
          </Box>
          <Box className={classess.Line}>
            <Box></Box>
          </Box>
          <Box className={classess.Title}>
            {propse.Entrance === "Anmeldung"
              ? "Anmeldung"
              : propse.Entrance === "Registrieren"
              ? "Registrieren"
              : "Mehr Info"}
          </Box>
          <Box className={classess.Bodi}>
            <Box
              sx={{ display: propse.Entrance === "" ? "flex" : "none" }}
              className={classess.DIE}
            >
              <span>
                Die Leitner Box ist eine Lernhilfe oder ein Karteikartensystem,
                das Lernenden hilft, sich Informationen effizient zu merken. Es
                wurde in den 1970er Jahren vom deutschen Psychologen Sebastian
                Leitner entwickelt.
              </span>
              <span>
                Die Leitner-Box besteht aus einem Satz Karteikarten oder
                Karteikarten, auf deren einer Seite jeweils eine Frage oder
                Information und auf der anderen Seite die Antwort steht. Die
                Karten sind in mehrere Kästchen, typischerweise vier oder fünf,
                unterteilt, die mit zunehmendem Schwierigkeitsgrad beschriftet
                sind.
              </span>
              <span>
                Um das Leitner-Box-System zu verwenden, überprüfen die Lernenden
                zunächst die Karten in der ersten Box. Wenn sie eine Frage
                richtig beantworten, wird die Karte in das nächste Feld
                verschoben. Bei einer falschen Antwort wird die Karte in das
                erste Feld zurückgelegt.
              </span>
              <span>
                Karten in den höheren Kästchen werden seltener wiederholt, da
                davon ausgegangen wird, dass der Lernende die Informationen
                bereits auswendig gelernt hat. Das Leitner-Box-System basiert
                auf dem Prinzip der räumlichen Wiederholung, d. Dies macht es zu
                einem effektiven Lernwerkzeug für Lernende jeden Alters und
                Niveaus.
              </span>
            </Box>
            <Box
              className={classess.BodiTitle}
              sx={{
                display:
                  propse.Entrance === "" || propse.Entrance === "Registrieren"
                    ? "none"
                    : "flex",
              }}
            >
              <span className={classess.Bitte}>Bitte melden Sie sich an</span>
              <span className={classess.Dein}>DEIN PROFIL</span>
              <UiText
                ID="EMAIL"
                Value={Email}
                Lable="E-mail"
                Error={Error1}
                OnChange={(e) => OnChange("EMAIL", e)}
                onClick={() => onClick("EMAIL")}
              />
              <UiPass
                ID="PASSWORD"
                Value={Password}
                Lable="Passwort"
                Error={Error2}
                OnChange={(e) => OnChange("PASSWORD", e)}
              />
            </Box>
            <Box
              className={classess.BodiTitle}
              sx={{
                display:
                  propse.Entrance === "" || propse.Entrance === "Anmeldung"
                    ? "none"
                    : "flex",
              }}
            >
              <UiUploading setImage={setImage} HTMLFor={propse.HTMLFor} />
              <UiText
                ID="N-R"
                Value={Name}
                Lable="Nachname"
                Error={Error3}
                OnChange={(e) => OnChange("NAME", e)}
                onClick={() => onClick("NAME")}
              />
              <UiText
                ID="EMAILREGISTER"
                Value={EmailRegister}
                Lable="E-mail"
                Error={Error4}
                OnChange={(e) => OnChange("EMAILREGISTER", e)}
                onClick={() => onClick("EMAILREGISTER")}
              />
              <UiText
                ID="CATEGORY"
                Value={Category}
                Lable="Kategorie"
                Error={Error5}
                OnChange={(e) => OnChange("CATEGORY", e)}
                onClick={() => onClick("CATEGORY")}
              />
              <UiPass
                ID="PASSWORDREGISTER"
                Value={PasswordRegister}
                Lable="Password"
                Error={Error6}
                OnChange={(e) => OnChange("PASSWORDREGISTER", e)}
              />
            </Box>
          </Box>
          <Box className={classess.Footer}>
            {propse.Entrance === "Anmeldung" ? (
              <BTN
                W="100%"
                H="45px"
                FontFamily="Poppins"
                FontSize="0.8rem"
                FontWeight="normal"
                BGC="#ed013e"
                Text="ANMELDUNG"
              />
            ) : propse.Entrance === "Registrieren" ? (
              <BTN
                W="100%"
                H="45px"
                FontFamily="Poppins"
                FontSize="0.8rem"
                FontWeight="normal"
                BGC="#ed013e"
                Text="REGISTRIEREN"
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Modal;
