import { Button } from "@mui/material";
import React from "react";

const BTN = ({ W, H, FontFamily, FontSize, FontWeight, BGC, Text }) => {
  return (
    <Button
      variant="contained"
      type="submit"
      sx={{
        backgroundColor: BGC,
        width: W,
        height: H,
        fontFamily: FontFamily,
        fontSize: FontSize,
        fontWeight: FontWeight,
      }}
      onMouseEnter={(e) => (e.target.style.backgroundColor = BGC)}
      onMouseLeave={(e) => (e.target.style.backgroundColor = BGC)}
    >
      {Text}
    </Button>
  );
};

export default BTN;
