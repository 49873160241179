import { Avatar, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Photo from "@mui/icons-material/AddAPhotoOutlined";

const UiUploading = (propse) => {
  const [avatarUrl, setAvatarUrl] = useState(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    propse.setImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = (event) => {
      setAvatarUrl(event.target.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    setAvatarUrl(null);
  }, []);
  return (
    <React.Fragment>
      <div component="label">
        <input
          type="file"
          id={propse.HTMLFor}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
        />
        <label htmlFor={propse.HTMLFor}>
          <Avatar
            sx={{ width: "70px", height: "70px" }}
            src={avatarUrl}
            alt="Profile Photo"
            style={{
              cursor: "pointer",
              display: avatarUrl === null ? "none" : "flex",
            }}
          />
          <Box
            sx={{
              width: "70px",
              height: "70px",
              backgroundColor: "rgba(90,90,90,0.1)",
              borderRadius: "50%",
              cursor: "pointer",
              display: avatarUrl === null ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontSize: "0.5rem",
              color: "rgba(70,70,70)",
            }}
          >
            <Photo sx={{ fontSize: "1.1rem" }} />
            Foto hochladen
          </Box>
        </label>
      </div>
    </React.Fragment>
  );
};

export default UiUploading;
