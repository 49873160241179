import { Box } from "@mui/material";
import React, { useState } from "react";
import classess from "./Social.module.css";
import { SocialIcon } from "react-social-icons";

const Social = (propse) => {
  const [EL, setEL] = useState("");
  return (
    <Box
      className={classess.Social}
      sx={{ marginTop: propse.MT, marginLeft: propse.ML, width: propse.W }}
    >
      <span className={classess.Copyright}>
        Copyright © Dezember-2021. Alle Rechte vorbehalten. Erstellt von Ramin
        Maghsoodi
      </span>
      <Box>
        <SocialIcon
          url="instagram"
          bgColor={
            EL === "instagram_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "instagram_RED" ? "#ed013e" : "#444444"}
          style={{
            width: "30px",
            height: "30px",
            marginLeft: "-7px",
          }}
          href="#"
          onMouseEnter={() => setEL("instagram_RED")}
          onMouseLeave={() => setEL("instagram")}
        />
        <SocialIcon
          url="dribbble"
          bgColor="rgba(100,100,100,0.0)"
          fgColor="#ed013e"
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
        />
        <SocialIcon
          url="linkedin"
          bgColor={
            EL === "linkedin_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "linkedin_RED" ? "#ed013e" : "#444444"}
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("linkedin_RED")}
          onMouseLeave={() => setEL("linkedin")}
        />
        <SocialIcon
          url="youtube"
          bgColor={
            EL === "youtube_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "youtube_RED" ? "#ed013e" : "#444444"}
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("youtube_RED")}
          onMouseLeave={() => setEL("youtube")}
        />
        <SocialIcon
          url="pinterest"
          bgColor={
            EL === "pinterest_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "pinterest_RED" ? "#ed013e" : "#444444"}
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("pinterest_RED")}
          onMouseLeave={() => setEL("pinterest")}
        />
        <SocialIcon
          url="twitter"
          bgColor={
            EL === "twitter_RED"
              ? "rgba(90,90,90,0.1)"
              : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "twitter_RED" ? "#ed013e" : "#444444"}
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("twitter_RED")}
          onMouseLeave={() => setEL("twitter")}
        />
        <SocialIcon
          url="gitlab"
          bgColor={
            EL === "gitlab_RED" ? "rgba(90,90,90,0.1)" : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "gitlab_RED" ? "#ed013e" : "#444444"}
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("gitlab_RED")}
          onMouseLeave={() => setEL("gitlab")}
        />
        <SocialIcon
          url="email"
          bgColor={
            EL === "email_RED" ? "rgba(90,90,90,0.1)" : "rgba(100,100,100,0.0)"
          }
          fgColor={EL === "email_RED" ? "#ed013e" : "#444444"}
          style={{ width: "30px", height: "30px", marginLeft: "-7px" }}
          href="#"
          onMouseEnter={() => setEL("email_RED")}
          onMouseLeave={() => setEL("email")}
        />
      </Box>
    </Box>
  );
};

export default Social;
