import { Box } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import classess from "./Coffee.module.css";
import COFFEE1 from "../../assets/COFFEE1.png";
import COFFEE2 from "../../assets/COFFEE2.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Social from "../../components/Social/Social";
import Menus from "../../components/Menus/Menus";
import ListIcon from "@mui/icons-material/List";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";
import Modal from "../../components/Modal/Modal";

const Coffee = (propse) => {
  const options = ["Anmeldung", "Registrieren"];
  const [Extra, setExtra] = useState(null);
  const [Entrance, setEntrance] = useState("");
  const HandleEntrance = (e) => {
    setExtra(true);
    setEntrance(e);
  };
  useEffect(() => {
    if (propse.W <= 560) setExtra(null);
  }, [propse.W]);
  return (
    <Fragment>
      <Modal
        W={propse.W}
        H={propse.H}
        Move={Extra}
        setMove={setExtra}
        Entrance={Entrance}
      />
      <Box className={classess.Wrapper}>
        <Box
          className={classess.Coffe1}
          sx={{
            width:
              propse.W <= 560
                ? "100%"
                : propse.W >= 561 && propse.W <= 768
                ? `${propse.W - 200}px`
                : `${propse.W - 300}px`,
            height: propse.W <= 560 ? `${propse.H - 150}px` : `${propse.H}px`,
          }}
        >
          <Box className={classess.Info}>
            <Box>
              <Box></Box>
            </Box>
            <Box>
              <Box className={classess.InfoIcon}>
                <Menus
                  options={options}
                  ITEM_HEIGHT="48"
                  Icon={<ListIcon />}
                  IconMenu1={
                    <BlurOnIcon
                      sx={{
                        fontSize: "1.3rem",
                        marginRight: "8px",
                      }}
                    />
                  }
                  IconMenu2={
                    <FilterDramaIcon
                      sx={{
                        fontSize: "1.3rem",
                        marginRight: "8px",
                      }}
                    />
                  }
                  onClick={(e) => HandleEntrance(e)}
                />
              </Box>
              <Box className={classess.InfoLine}></Box>
              <span className={classess.InfoDU}>DU WEIßT Über</span>
              <span className={classess.InfoLB}>Lietner Box</span>
              <span className={classess.AUL} onClick={() => HandleEntrance("")}>
                ZEIG MEHR{" "}
                <ArrowForwardIcon
                  sx={{
                    width: "12px",
                    height: "auto",
                    position: "relative",
                    top: "2px",
                  }}
                />
              </span>
              <Box className={classess.InfoDIE}>
                Die Leitner Box ist eine Lernhilfe oder ein Karteikartensystem,
                das Lernenden hilft, sich Informationen effizient zu merken. Es
                wurde in den 1970er Jahren vom deutschen Psychologen Sebastian
                Leitner entwickelt. <br /> <br />
                Die Leitner-Box besteht aus einem Satz Karteikarten oder
                Karteikarten, auf deren einer Seite jeweils eine Frage oder
                Information und auf der anderen Seite die Antwort steht. Die
                Karten sind in mehrere Kästchen, typischerweise vier oder fünf,
                unterteilt, die mit zunehmendem Schwierigkeitsgrad beschriftet
                sind. <br /> <br />
                Um das Leitner-Box-System zu verwenden, überprüfen die Lernenden
                zunächst die Karten in der ersten Box. Wenn sie eine Frage
                richtig beantworten, wird die Karte in das nächste Feld
                verschoben. Bei einer falschen Antwort wird die Karte in das
                erste Feld zurückgelegt.
              </Box>
              <Social
                MT={propse.W <= 560 ? "40px" : propse.H >= 600 ? "40px" : "0px"}
                ML="15px"
                W={propse.W <= 560 ? "80%" : "100%"}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={classess.Coffe2}
          sx={{
            width:
              propse.W <= 560
                ? "100%"
                : propse.W >= 561 && propse.W <= 768
                ? "200px"
                : "300px",
            height: propse.W <= 560 ? "150px" : `${propse.H}px`,
          }}
        >
          <img
            src={COFFEE2}
            alt=""
            className={classess.COFFEE2}
            style={{
              marginLeft: propse.W <= 560 ? `${(propse.W - 150) / 2}px` : "",
            }}
          />
          <img
            src={COFFEE1}
            alt=""
            className={classess.COFFEE1}
            style={{
              marginTop:
                propse.W >= 561 && propse.W <= 768
                  ? `${(propse.H - 176) / 2}px`
                  : propse.W >= 769 && propse.W <= 990
                  ? `${(propse.H - 235) / 2}px`
                  : `${(propse.H - 270) / 2}px`,
            }}
          />
          <Box
            className={classess.Portfolio}
            sx={{
              display:
                propse.W <= 560 ? "none" : propse.H < 465 ? "none" : "flex",
            }}
          >
            <Box>PORTFOLIO</Box>
            <Box>LEITNER BOX</Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Coffee;
