import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const Menus = (propse) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    if (e.size !== 0) {
      propse.onClick(e);
    }
  };
  const handleClose2 = (e) => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {propse.Icon}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose2}
        PaperProps={{
          style: {
            maxHeight: propse.ITEM_HEIGHT * 4.5,
            width: "18ch",
          },
        }}
      >
        {propse.options.map((option, id) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => handleClose(option)}
            sx={{
              fontSize: "0.8rem",
              color: "rgba(70,70,70)",
            }}
            onMouseEnter={(e) =>
              (e.target.style.backgroundColor = "rgba(255,195,0,0.2)")
            }
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#FFF")}
          >
            {id === 0 ? propse.IconMenu1 : propse.IconMenu2}
            <span style={{ marginTop: "3px" }}>{option}</span>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default Menus;
