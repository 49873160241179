import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const UiText = (propse) => {
  const [Correct, setCorrect] = useState(false);
  const onClick = () => {
    propse.onClick();
    setCorrect(false);
  };
  useEffect(() => {
    setCorrect(false);
  }, []);
  return (
    <React.Fragment>
      <TextField
        id={propse.ID}
        variant="standard"
        size="small"
        value={propse.Value}
        sx={{
          width: "100%",
        }}
        label={
          <span
            style={{
              position: "relative",
              fontSize: "0.8rem",
              fontFamily: "Poppins",
              color: "rgba(0, 0, 0, 0.5)",
            }}
          ></span>
        } // lable
        placeholder={propse.Lable}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          disableUnderline: false,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                position: "relative",
                left: "0px",
              }}
            >
              <Close
                onClick={onClick}
                sx={{
                  cursor: "pointer",
                  color: "#7f7f7f",
                  width: "18px",
                  height: "18px",
                  marginTop: "-1px",
                  display: propse.Error
                    ? "none"
                    : propse.Value.length !== 0
                    ? Correct
                      ? "none"
                      : "flex"
                    : "none",
                }}
              />
              <Close
                onClick={onClick}
                sx={{
                  cursor: "pointer",
                  color: "#ed013e",
                  width: "18px",
                  height: "18px",
                  marginTop: "-1px",
                  display: propse.Error ? "flex" : "none",
                }}
              />
              <CheckCircleRoundedIcon
                onClick={onClick}
                sx={{
                  cursor: "pointer",
                  color: "#088f27",
                  width: "18px",
                  height: "18px",
                  marginTop: "-1px",
                  display: propse.Error ? "none" : Correct ? "flex" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            fontSize: "0.8rem",
            paddingTop: "6px",
            paddingBottom: "6px",
            fontFamily: "Poppins",
            color: "rgba(0,0,0,0.8)",
            paddingLeft: "8px",
            paddingRight: "8px",
          },
        }} // font size of input text
        onChange={propse.OnChange}
        //
        onBlur={() =>
          propse.Value.length !== 0 ? setCorrect(true) : setCorrect(false)
        }
        onFocus={() => setCorrect(false)}
      />
    </React.Fragment>
  );
};

export default UiText;
