import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import PassON from "@mui/icons-material/VisibilityOutlined";
import PassOFF from "@mui/icons-material/VisibilityOffOutlined";
import Close from "@mui/icons-material/HighlightOffOutlined";

const UiPass = (propse) => {
  const [Type, setType] = useState("password");
  const onClick = (e) => {
    if (e === "PASS") setType("text");
    if (e === "TEXT") setType("password");
  };

  return (
    <React.Fragment>
      <TextField
        type={Type}
        id={propse.ID}
        variant="standard"
        size="small"
        value={propse.Value}
        sx={{
          width: "100%",
        }}
        label={
          <span
            style={{
              position: "relative",
              fontSize: "0.8rem",
              fontFamily: "Poppins",
              color: "rgba(0, 0, 0, 0.5)",
            }}
          ></span>
        } // lable
        placeholder={propse.Lable}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          disableUnderline: false,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                position: "relative",
                left: "0px",
                // display: propse.Value.length !== 0 ? "flex" : "none",
              }}
            >
              <PassON
                sx={{
                  width: "18px",
                  height: "auto",
                  cursor: "pointer",
                  color: "#7f7f7f",
                  display: propse.Error
                    ? "none"
                    : Type === "text"
                    ? "flex"
                    : "none",
                }}
                onClick={() => onClick("TEXT")}
              />
              <PassOFF
                sx={{
                  width: "18px",
                  height: "auto",
                  cursor: "pointer",
                  color: "#7f7f7f",
                  display: propse.Error
                    ? "none"
                    : Type === "password"
                    ? "flex"
                    : "none",
                }}
                onClick={() => onClick("PASS")}
              />
              <Close
                onClick={onClick}
                sx={{
                  cursor: "pointer",
                  color: "#ed013e",
                  width: "18px",
                  height: "18px",
                  marginTop: "-1px",
                  display: propse.Error ? "flex" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            fontSize: "0.8rem",
            paddingTop: "6px",
            paddingBottom: "6px",
            fontFamily: "Poppins",
            color: "rgba(0,0,0,0.8)",
            //backgroundColor: "rgba(100,200,200,0.1)",
            paddingLeft: "8px",
            paddingRight: "8px",
          },
          // maxLength:
          //   propse.UiLable === "Phone" ||
          //   propse.UiLable === "Phone Number" ||
          //   propse.UiLable === "Cellphone"
          //     ? 13
          //     : null,
        }} // font size of input text
        onChange={propse.OnChange}
      />
    </React.Fragment>
  );
};

export default UiPass;
